.seller-layout-1 header {
  background-color: #343a40;
  padding: 20px;
  text-align: center;
  color: #fff;
}
.seller-layout-1 .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.seller-layout-1 header .logo img {
  width: 150px;
}

.seller-layout-1 main {
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
}

.seller-layout-1 section {
  padding: 40px;
  border-bottom: 1px solid #ddd;
}

.seller-layout-1 section h2 {
  color: #007bff;
  margin-bottom: 15px;
}

.seller-layout-1 section p {
  line-height: 1.6;
}

/* Management Team Grid */
.seller-layout-1 #management-team .team-grid {
  display: flex;
  gap: 20px;
}

.seller-layout-1 .team-member {
  flex: 1;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.seller-layout-1 .team-member h3 {
  margin-bottom: 10px;
}

/* In-Person Services Grid */
.seller-layout-1 #in-person-services .services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.seller-layout-1 .service-item {
  background-color: #e9ecef;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
}

/* Footer */
.seller-layout-1 footer {
  background-color: #343a40;
  color: white;
  text-align: center;
  padding: 15px;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .seller-layout-1 section {
    padding: 20px;
  }
  .seller-layout-1 #management-team .team-grid,
  .seller-layout-1 #in-person-services .services-grid {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
}
