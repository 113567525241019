/* Header and Hero Banner */
.seller-layout-2 header {
  background-color: #0073e6;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

.seller-layout-2 .header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.seller-layout-2 .header-container .logo {
  display: flex;
  align-items: center;
}

.seller-layout-2 .header-container .logo img {
  width: 50px;
  margin-right: 10px;
}

.seller-layout-2 header nav ul {
  display: flex;
  gap: 15px;
  list-style: none;
}

.seller-layout-2 header nav a {
  color: #fff;
  text-decoration: none;
}

.seller-layout-2 .hero {
  padding: 60px 20px;
}

.seller-layout-2 .hero h1 {
  font-size: 2.5em;
}

.seller-layout-2 .hero p {
  margin: 20px 0;
}

.seller-layout-2 .cta-button {
  background-color: #ff6700;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
}

/* Section Styles */
.seller-layout-2 section {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.seller-layout-2 section h2 {
  color: #0073e6;
  text-align: center;
  margin-bottom: 40px;
}

/* Services Section */
.seller-layout-2 .services-grid {
  display: flex;
  gap: 20px;
}

.seller-layout-2 .service {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Team Section */
.seller-layout-2 .team-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.seller-layout-2 .team-member {
  text-align: center;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.seller-layout-2 .team-member img {
  width: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

/* Testimonials Section */
.seller-layout-2 .testimonials {
  display: flex;
  gap: 20px;
  justify-content: center;
  text-align: center;
}

.seller-layout-2 blockquote {
  background: #fff;
  padding: 20px;
  border-left: 5px solid #0073e6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Contact Form */
.seller-layout-2 form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.seller-layout-2 input,
.seller-layout-2 textarea,
.seller-layout-2 button {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.seller-layout-2 button.cta-button {
  background-color: #0073e6;
  color: #fff;
  cursor: pointer;
  border: none;
}

/* Footer */
.seller-layout-2 footer {
  background-color: #0073e6;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}
