.radio_input[type="radio"]:checked,
.radio_input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
    display: none;
}

.radio_input[type="radio"]:checked + label,
.radio_input[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 18px;
}

.radio_input[type="radio"]:checked + label:before,
.radio_input[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(var(--color-gray-300));
    border-radius: 100%;
    background-color: #ffffff;
}

.radio_input[type="radio"]:checked + label:before {
    border-color: rgb(var(--color-accent));
}

.radio_input[type="radio"]:checked + label:after,
.radio_input[type="radio"]:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: rgb(var(--color-accent));
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.radio_input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.radio_input[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
